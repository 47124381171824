"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'デジロト',
    organizationId: '7kLqELsT4VS5iHwv7ZIW',
    version: '1.0',
    ionicAppId: '',
    applicationKey: 'digilot',
    applicationType: 'organization',
    artistId: undefined,
    languages: ['ja', 'en'],
    ios: {
        bundleId: '',
        appId: '',
    },
    android: {
        bundleId: '',
    },
    platform: ['web'],
    custom: {
        modules: {
            tutorial: true,
            trade: true,
            membership: true,
            thread: true,
            prepaidPoint: {
                chargeable: true,
            },
            gacha: true,
            nft: true,
            liveStream: false,
            item: true,
            store: true,
            campaign: true,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: false,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: ['home', 'myCollections', 'campaign-events', 'artist'],
        artistTabs: ['home', 'collections', 'gachas', 'trade', 'threads'],
    },
    deeplinkUrl: 'https://digilot.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/3161857',
    storagePath: 'digilot',
    dynamicLinkPrefix: 'digilot',
    deeplinkProd: 'digilot.utoniq.com',
};
exports.default = appConfig;
